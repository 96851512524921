import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "recompose";
import { Field } from "redux-form";

import { Card, Form, FormControl } from "@dpdgroupuk/mydpd-ui";
import {
  createLogEntryPayload,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import { A, F, M } from "../../../../../constants";

const ShippingDetails = ({
  countries,
  minDate,
  maxDate,
  excludeDates,
  onInputChange,
}) => (
  <Card>
    <Card.Header>{M.SHIPPING_DETAILS}</Card.Header>
    <Container className="p-0">
      <Row>
        <Col>
          <Field
            name={F.SHIPPING_DEFAULTS.SHARE_PROFILE}
            id={F.SHIPPING_DEFAULTS.SHARE_PROFILE}
            component={Form.Check}
            label={M.USE_SHARED_ADDRESS_BOOK}
            type="checkbox"
          />
        </Col>
        <Col>
          <Field
            name={F.SHIPPING_DEFAULTS.SHARE_PRODUCT_BOOK}
            id={F.SHIPPING_DEFAULTS.SHARE_PRODUCT_BOOK}
            component={Form.Check}
            label={M.USE_SHARED_PRODUCT_BOOK}
            type="checkbox"
          />
        </Col>
      </Row>

      <Row>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.COUNTRY}
            component={FormControl.Dropdown}
            values={[
              { label: M.PLEASE_SELECT_$(M.COUNTRY), value: null },
              ...countries,
            ]}
            label={M.COUNTRY}
            placeholder={M.PLEASE_SELECT_$(M.COUNTRY)}
            textTransform={M.UPPERCASE}
            onChange={() => {
              onInputChange(F.SHIPPING_DEFAULTS.COUNTRY);
            }}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE}
            values={
              F.SHIPPING_DEFAULTS.SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE_VALUES
            }
            component={FormControl.Dropdown}
            label={M.SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE}
            textTransform={M.UPPERCASE}
            onChange={() => {
              onInputChange(
                F.SHIPPING_DEFAULTS.SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE
              );
            }}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DEFAULT_SHIPMENT_DATE}
            component={FormControl.DatePicker}
            label={M.DEFAULT_SHIPMENT_DATE}
            onChange={value => {
              onInputChange(F.SHIPPING_DEFAULTS.DEFAULT_SHIPMENT_DATE, value);
            }}
            convertOutputDateToString={false}
            minDate={minDate}
            maxDate={maxDate}
            excludeDates={excludeDates}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            name={F.SHIPPING_DEFAULTS.ENABLE_WEEKEND}
            id={F.SHIPPING_DEFAULTS.ENABLE_WEEKEND}
            component={Form.Check}
            label={M.ALLOW_PARCELS_TO_BE_SHIPPED}
            type="checkbox"
          />
        </Col>
      </Row>

      <Row>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DEFAULT_INFORMATION_1}
            component={FormControl.Input}
            label={`${M.DEFAULT_INFORMATION} 1`}
            helperText={M.MAX_$_CHARACTERS(50)}
            maxLength={50}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DEFAULT_INFORMATION_2}
            component={FormControl.Input}
            label={`${M.DEFAULT_INFORMATION} 2`}
            helperText={M.MAX_$_CHARACTERS(50)}
            maxLength={50}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DEFAULT_INFORMATION_3}
            component={FormControl.Input}
            label={`${M.DEFAULT_INFORMATION} 3`}
            helperText={M.MAX_$_CHARACTERS(50)}
            maxLength={50}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DEFAULT_INFORMATION_4}
            component={FormControl.Input}
            label={`${M.DEFAULT_INFORMATION} 4`}
            helperText={M.MAX_$_CHARACTERS(50)}
            maxLength={50}
          />
        </Col>
        <Col sm={4}>
          <Field
            name={F.SHIPPING_DEFAULTS.DEFAULT_INFORMATION_5}
            component={FormControl.Input}
            label={`${M.DEFAULT_INFORMATION} 5`}
            helperText={M.MAX_$_CHARACTERS(50)}
            maxLength={50}
          />
        </Col>
      </Row>
    </Container>
  </Card>
);

ShippingDetails.propTypes = {
  onInputChange: PropTypes.func,
  countries: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  excludeDates: PropTypes.array,
};

export default compose(
  withTrack({
    loadId: A.SHIPPING_DEFAULTS_IDS.LOAD,
    interfaceId: A.SHIPPING_DEFAULTS_IDS.INTERFACE_ID,
  }),
  withTrackProps(() => ({
    onInputChange: (type, value) => {
      switch (type) {
        case F.SHIPPING_DEFAULTS.COUNTRY:
          return A.SHIPPING_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_COUNTRY;
        case F.SHIPPING_DEFAULTS.SHIPMENT_ADDRESS_BOOK_SEARCH_TYPE:
          return A.SHIPPING_DEFAULTS_IDS.CLICK_DROPDOWN_LIST_ADDRESSBOOK_TYPE;
        case F.SHIPPING_DEFAULTS.DEFAULT_SHIPMENT_DATE:
        default:
          return createLogEntryPayload(
            A.SHIPPING_DEFAULTS_IDS.CLICK_DEFAULT_SHIPMENT_DATE,
            { value }
          );
      }
    },
  }))
)(ShippingDetails);
