import moment from "moment";

export const getISODateString = date =>
  moment(new Date(date)).format("YYYY-MM-DD");

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(date.getDate() + days);
  return result;
};

export const getWeekends = (start, end) => {
  const result = [];
  while (start < end) {
    if (start.getDay() === 0 || start.getDay() === 6) {
      result.push(new Date(+start));
    }
    start = addDays(start, 1);
  }
  return result;
};

export const getWeekendNextMonday = date => {
  if (isWeekend(date)) {
    return date.getDay() === 6 ? addDays(date, 2) : addDays(date, 1);
  }
  return date;
};

export const isWeekend = date => {
  const dayNumber = new Date(date).getDay();
  return dayNumber === 0 || dayNumber === 6;
};
