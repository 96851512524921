import { PRINTER_DRIVER_TYPE, PRINTER_TYPE } from "@dpdgroupuk/mydpd-enums";

import { CITIZEN_TSC, ELTRON_ZEBRA } from "../strings";

export const PRINT_TO_THERMAL = "printToThermal";
export const THERMAL_PRINTER_TYPE = "thermalPrinterType";
export const THERMAL_PRINTER_NAME = "thermalPrinterName";
export const THERMAL_PRINTER_ASSET_NUMBER = "thermalPrinterAssetNumber";
export const THERMAL_PRINTER_DRIVER_TYPE = "thermalPrinterDriverType";
export const THERMAL_PRINTER_VENDOR_ID = "thermalPrinterVendorId";
export const THERMAL_PRINTER_PRODUCT_ID = "thermalPrinterProductId";
export const MISSING_ASSET_NUMBER = "missingAssetNumber";
export const LABELS_PER_PAGE = "labelsPerPage";
export const ONE_LABEL_PER_PAGE = 1;
export const TWO_LABELS_PER_PAGE = 2;

export const FIELDS = [
  PRINT_TO_THERMAL,
  THERMAL_PRINTER_TYPE,
  THERMAL_PRINTER_NAME,
  THERMAL_PRINTER_DRIVER_TYPE,
  THERMAL_PRINTER_VENDOR_ID,
  THERMAL_PRINTER_PRODUCT_ID,
  THERMAL_PRINTER_ASSET_NUMBER,
  MISSING_ASSET_NUMBER,
  LABELS_PER_PAGE,
];

export const MISSING_ASSET_NUMBER_VALUE = "000000";

export const THERMAL_PRINTER_TYPES = [
  { label: CITIZEN_TSC, value: PRINTER_TYPE.CITIZEN },
  { label: ELTRON_ZEBRA, value: PRINTER_TYPE.ELTRON },
];

export const DEFAULT_VALUES = {
  [PRINT_TO_THERMAL]: false,
  [THERMAL_PRINTER_TYPE]: THERMAL_PRINTER_TYPES[0].value,
  [THERMAL_PRINTER_NAME]: "",
  [THERMAL_PRINTER_DRIVER_TYPE]: PRINTER_DRIVER_TYPE.NONE,
  [THERMAL_PRINTER_ASSET_NUMBER]: "",
  [MISSING_ASSET_NUMBER]: false,
  [LABELS_PER_PAGE]: ONE_LABEL_PER_PAGE,
};
