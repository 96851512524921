import classNames from "classnames";
import { Container } from "react-bootstrap";

import { useLocalServiceState } from "@dpdgroupuk/mydpd-app";
import { Card } from "@dpdgroupuk/mydpd-ui";

import { localApis } from "../../../../../../apis";
import { M } from "../../../../../../constants";
import DownloadInstallerRow from "./DownloadInstallerRow";
import styles from "./MyDPDSettings.module.scss";
import StatusRow from "./StatusRow";
import PropTypes from "prop-types";

const help = [
  {
    name: "About",
    content: (
      <>
        <p>That app extends browser functionality.</p>
        <p>
          App allows you to configure printer drivers and to print labels
          avoiding browser prompt window. Also you will be able to use Import
          Activity functionality to work with your file system.
        </p>
      </>
    ),
  },
  {
    name: "Install",
    content: (
      <>
        <p>
          Before installing, please to be sure, port <b>2917</b> is free to use.
        </p>
        <p>We recommend to use admin permissions to install app</p>
        <br />
        <p>
          Once app installed, it will be added into auto run service of your OS
          which means you do not need to start it again after each computer
          restarting
        </p>
      </>
    ),
  },
];

const MyDPDSettings = ({ isShowStatus }) => {
  const { isRunning } = useLocalServiceState();
  return (
    <Card>
      <Card.Header
        className={classNames(
          "mb-4 d-flex justify-content-between",
          styles.title
        )}
      >
        {M.MYDPD_CUSTOMER_SETTINGS}
        <Card.HelpIcon help={help} helpModalTitle={M.MYDPD_CUSTOMER_SETTINGS} />
      </Card.Header>
      <Container className="p-0 mb-4">
        {isShowStatus ? <StatusRow isRunning={isRunning} /> : ""}

        {!localApis.isDesktopClient && (
          <DownloadInstallerRow className={"mt-4"} />
        )}
      </Container>
    </Card>
  );
};

MyDPDSettings.propTypes = {
  isShowStatus: PropTypes.bool,
};

export default MyDPDSettings;
