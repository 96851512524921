import get from "lodash/get";
import moment from "moment";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { F } from "../../../../../constants";
import { UserSelectors } from "../../../../../store/user";
import * as ShippingDefaultsModel from "./model";

export const getShippingDefaultsFormValues = getFormValues(
  F.FORMS_NAMES.SHIPPING_DEFAULTS_FORM
);

export const getIsWeekendEnable = createSelector(
  getShippingDefaultsFormValues,
  formValues => get(formValues, F.SHIPPING_DEFAULTS.ENABLE_WEEKEND)
);

export const getShipmentMinDate = () => moment().startOf("day").toDate();

export const getShipmentMaxDate = createSelector(
  getShipmentMinDate,
  UserSelectors.getIsForwardDateOver7Days,
  ShippingDefaultsModel.getShipmentMaxDate
);

export const getShipmentExcludeDates = createSelector(
  getIsWeekendEnable,
  getShipmentMinDate,
  getShipmentMaxDate,
  ShippingDefaultsModel.getShipmentExcludeDates
);

export const getShippingDate = createSelector(
  getShippingDefaultsFormValues,
  getShipmentMinDate,
  (formValues, defaultDate) =>
    get(formValues, F.SHIPPING_DEFAULTS.DEFAULT_SHIPMENT_DATE, defaultDate)
);
