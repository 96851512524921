import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle, withState } from "recompose";
import { change, propTypes } from "redux-form";

import { LocalServicePropType, withLocalService } from "@dpdgroupuk/mydpd-app";
import { PRINTER_DRIVER_TYPE } from "@dpdgroupuk/mydpd-enums";
import { Button, Card, Legend, Main, withOverlay } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { A, F, M, SNACKBAR } from "../../../../constants";
import { withFormController, withNotification } from "../../../../hocs";
import { UserModel } from "../../../../models";
import { AuthSelectors } from "../../../../store/auth";
import { AccountDetailsActions } from "../../redux";
import {
  LaserPrinterSettings,
  MyDPDSettings,
  ThermalPrinterSettings,
  WorkstationConfigModal,
} from "./components";
import {
  PrintingSettingsActions,
  PrintingSettingsModel,
  PrintingSettingsSelectors,
  PrintingSettingsValidator,
} from "./redux";
import { isSupportedWebUsbDriver } from "./redux/model";
import { localApis } from "../../../../apis";

const PRINT_TO_THERMAL = F.PRINTING_SETTINGS.PRINT_TO_THERMAL;
const THERMAL_PRINTER_TYPE = F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE;
const THERMAL_PRINTER_DRIVER_TYPE =
  F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE;

const { REACT_APP_MYDPD_APP_VERSION } = process.env;

const isVersionMoreThan = user => {
  if (user && user.dpdLocalAppVersionActual) {
    const version = parseInt(user.dpdLocalAppVersionActual.replace(/\./g, ""));
    const compareVersion = REACT_APP_MYDPD_APP_VERSION || "2.2.0";
    return version >= parseInt(compareVersion.replace(/\./g, ""));
  }
  return false;
};

const PrintingSettings = props => (
  <>
    <Main.Body>
      <Legend
        leftMessage={M.COMPLETE_REQUIRED_FIELDS}
        rightMessage={UserModel.getUserDetailsString(props.user)}
      />
      <Card.Stack fluid>
        <Col md={12}>
          <MyDPDSettings
            isShowStatus={
              !isVersionMoreThan(props.user) && !localApis.isDesktopClient
            }
          />
        </Col>
        {isVersionMoreThan(props.user) && !localApis.isDesktopClient ? (
          ""
        ) : (
          <Col md={12}>
            <ThermalPrinterSettings {...props} />
          </Col>
        )}
        <Col md={12}>
          <LaserPrinterSettings
            isSectionDisabled={props.printToThermal}
            onInputChange={props.onInputChange}
          />
        </Col>
      </Card.Stack>
      <WorkstationConfigModal
        show={props.showWorkstationModal}
        onClose={() => props.setShowWorkstationModal(false)}
        saveWorkstationConfig={props.saveWorkstationConfig}
      />
    </Main.Body>
    <Main.Footer className="dark">
      <Button.Toolbar>
        <Button onClick={props.onSubmit}>{M.SAVE}</Button>
        <Button
          disabled={
            !props.localService.state.isRunning ||
            !props.printToThermal ||
            props.printerDriverType !== PRINTER_DRIVER_TYPE.MYDPD_SERVICE
          }
          onClick={() => props.setShowWorkstationModal(true)}
        >
          {M.CONFIGURE_PRINT_WORKSTATION}
        </Button>
      </Button.Toolbar>
    </Main.Footer>
  </>
);

PrintingSettings.propTypes = {
  printers: PropTypes.array,
  localService: LocalServicePropType,
  thermalPrinterName: PropTypes.string,
  isTestPrinterAvailable: PropTypes.bool,
  isConfigureAvailable: PropTypes.bool,
  printToThermal: PropTypes.bool,
  showWorkstationModal: PropTypes.bool,
  setShowWorkstationModal: PropTypes.func,
  missingAssetNumber: PropTypes.bool,
  onSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  message: PropTypes.string,
  onTestPrinter: PropTypes.func,
  onConfigurePrinter: PropTypes.func,
  onConfigureUsbWebPrinter: PropTypes.func,
  onChangeMissingAssetNumberField: PropTypes.func,
  ...propTypes,
};

const mapStateToProps = state => ({
  user: AuthSelectors.getUser(state),
  printers: PrintingSettingsSelectors.getPrintersKeyValue(state),
  thermalPrinterName: PrintingSettingsSelectors.getThermalPrinterName(state),
  isConfigureAvailable:
    PrintingSettingsSelectors.isConfigurePrinterAvailable(state),
  isTestPrinterAvailable:
    PrintingSettingsSelectors.isTestPrinterAvailable(state),
  missingAssetNumber: PrintingSettingsSelectors.isMissingAssetNumber(state),
  printToThermal: PrintingSettingsSelectors.isPrintToThermal(state),
  printerDriverType:
    PrintingSettingsSelectors.getThermalPrinterDriverType(state),
  supportsWebUsbApi: isSupportedWebUsbDriver(),
});

const mapDispatchToProps = (
  dispatch,
  {
    snackbar,
    withErrorNotification,
    withFormSubmitNotification,
    setShowWorkstationModal,
    overlay,
    localService,
  }
) => ({
  onTestPrinter: withErrorNotification(async () => {
    try {
      overlay.show();
      await dispatch(PrintingSettingsActions.testPrinter());
      snackbar.showSuccess({
        message: M.TEST_LABEL_HAS_BEEN_SENT_TO_PRINT_QUEUE,
        displayTime: SNACKBAR.SHOW_ALERT_DISPLAY_TIME / 3,
      });
    } finally {
      overlay.hide();
    }
  }),
  onConfigurePrinter: withErrorNotification(async () => {
    try {
      overlay.show();
      const { name } = await dispatch(
        PrintingSettingsActions.configurePrinter()
      );
      await dispatch(PrintingSettingsActions.fetchPrinters());
      snackbar.showSuccess({
        message: M.PRINTER_$_INSTALLED_SUCCESSFULLY(name),
        displayTime: SNACKBAR.SHOW_ALERT_DISPLAY_TIME / 3,
      });
    } finally {
      overlay.hide();
    }
  }),
  saveWorkstationConfig: withFormSubmitNotification(
    overlay.showWhile(async values => {
      setShowWorkstationModal(false);
      await dispatch(PrintingSettingsActions.saveWorkstationConfig(values));
    })
  ),
  resetPrintersState: () =>
    dispatch(PrintingSettingsActions.resetPrintersState()),
  onChangeMissingAssetNumberField: isMissingAssetNumber =>
    dispatch(
      PrintingSettingsActions.onChangeMissingAssetNumberField(
        isMissingAssetNumber
      )
    ),
  onConfigureUsbWebPrinter: withErrorNotification(() =>
    dispatch(PrintingSettingsActions.onConfigureUsbWebPrinter())
  ),
  fetchInitialData: () => async dispatch => {
    const [prefs, printers] = await Promise.all([
      dispatch(AccountDetailsActions.fetchPreferences()),
      localService.manager
        .checkStatus()
        .then(() => {
          return dispatch(PrintingSettingsActions.fetchPrinters());
        })
        // ignore error here to avoid snackbar here
        .catch(() => {}),
    ]);
    return { data: { ...prefs.data, printers: printers?.data || [] } };
  },
});

export default compose(
  withOverlay,
  withNotification,
  withState("showWorkstationModal", "setShowWorkstationModal", false),
  withLocalService,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFormController({
    form: F.FORMS_NAMES.PRINTING_SETTING_FORM,
    shouldError: ({ values }) => {
      const errors = PrintingSettingsValidator.validate(values);
      const mappedErrorsLength = Object.keys(errors).length;
      return !!mappedErrorsLength;
    },
    onLoad: ownProps => ownProps.fetchInitialData(),
    validate: PrintingSettingsValidator.validate,
    onChange: (values, dispatch, props, previousValues) => {
      if (
        previousValues[PRINT_TO_THERMAL] === false &&
        values[PRINT_TO_THERMAL] &&
        !props.pristine
      ) {
        dispatch(
          change(
            F.FORMS_NAMES.PRINTING_SETTING_FORM,
            THERMAL_PRINTER_TYPE,
            values[PRINT_TO_THERMAL]
              ? values[THERMAL_PRINTER_TYPE] ||
                  F.PRINTING_SETTINGS.DEFAULT_VALUES[THERMAL_PRINTER_TYPE]
              : 0,
            true
          )
        );
        if (values[PRINT_TO_THERMAL]) {
          dispatch(
            change(
              F.FORMS_NAMES.PRINTING_SETTING_FORM,
              THERMAL_PRINTER_DRIVER_TYPE,
              PRINTER_DRIVER_TYPE.MYDPD_SERVICE,
              true
            )
          );
        }
      }
      if (!values[PRINT_TO_THERMAL]) {
        dispatch(
          change(
            F.FORMS_NAMES.PRINTING_SETTING_FORM,
            THERMAL_PRINTER_TYPE,
            0,
            true
          )
        );
      }
    },
    onSubmit: PrintingSettingsActions.updateForm,
    mapDataToForm: PrintingSettingsModel.mapDataToForm,
    entityName: M.PRINTING_SETTINGS,
  }),
  withTrack({
    loadId: A.PRINTING_SETTINGS_IDS.LOAD,
    interfaceId: A.PRINTING_SETTINGS_IDS.INTERFACE_ID,
  }),
  withTrackProps({
    onSubmit: A.PRINTING_SETTINGS_IDS.CLICK_SAVE,
    onInputChange: type => {
      switch (type) {
        case F.PRINTING_SETTINGS.THERMAL_PRINTER_TYPE:
          return A.PRINTING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRINTER_TYPE;
        case F.PRINTING_SETTINGS.THERMAL_PRINTER_NAME:
          return A.PRINTING_SETTINGS_IDS.CLICK_DROPDOWN_LIST_PRINTER_NAME;
        case F.PRINTING_SETTINGS.PRINT_TO_THERMAL:
          return A.PRINTING_SETTINGS_IDS
            .CLICK_CHECKBOX_PRINTING_TO_THERMAL_PRINTER;
        case F.PRINTING_SETTINGS.LABELS_PER_PAGE:
          return A.PRINTING_SETTINGS_IDS.CLICK_RADIO_BUTTON_LABELS_PER_PAGE;
        case F.PRINTING_SETTINGS.THERMAL_PRINTER_DRIVER_TYPE:
          return A.PRINTING_SETTINGS_IDS.CLICK_RADIO_BUTTON_DRIVER_TYPE;
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { localService } = this.props;

      this.intervalId = setInterval(() => {
        localService.manager.checkStatus();
      }, 10000);

      if (
        this.props.location.hash === "#showWorkstationModal" &&
        this.props.localService.state.isRunning &&
        this.props.printToThermal &&
        this.props.printerDriverType === PRINTER_DRIVER_TYPE.MYDPD_SERVICE
      ) {
        this.props.setShowWorkstationModal(true);
      }
    },

    componentDidUpdate(prevProps) {
      if (
        prevProps.localService.state.isRunning === false &&
        prevProps.localService.state.isRunning !==
          this.props.localService.state.isRunning
      ) {
        this.props.dispatch(PrintingSettingsActions.fetchPrinters());
      }
    },

    componentWillUnmount() {
      this.intervalId && clearInterval(this.intervalId);
      this.props.resetPrintersState();
    },
  })
)(PrintingSettings);
