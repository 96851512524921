import PropTypes from "prop-types";

import styles from "./Card.module.scss";

export const Card = ({ cardContent }) => {
  if (!cardContent) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles.title}>
        <b>{cardContent.title}</b>
      </div>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: cardContent.body,
        }}
      />
    </div>
  );
};

Card.propTypes = {
  cardContent: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};
