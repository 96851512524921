import get from "lodash/get";
import { createSelector } from "reselect";

export const getData = state => state.app.user;

export const getUser = createSelector(getData, ({ user = {} }) => user);

export const getAdvancedConfiguration = createSelector(
  getData,
  ({ advancedConfiguration = {} }) => advancedConfiguration
);

export const getIsForwardDateOver7Days = createSelector(
  getAdvancedConfiguration,
  advancedConfiguration =>
    get(advancedConfiguration, "forwardDateOver7Days", false)
);
