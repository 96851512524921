import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { AuthUtil, useAppUser } from "@dpdgroupuk/mydpd-app";
import { Main } from "@dpdgroupuk/mydpd-ui";
import { trackProps, withTrack } from "@dpdgroupuk/react-event-tracker";

import { Card } from "../../components/Card";
import { BANNER_THEMES, WideBanner } from "../../components/WideBanner";
import { API_PAGE } from "../../constants/analytics";
import { PAGES } from "../../constants/cms";
import { fetchPageData } from "../../store/cms/actions";

const ApiPage = ({ fetchAPIData }) => {
  const user = useAppUser();
  const [pageData, setPageData] = useState(null);

  useEffect(async () => {
    const pageDescriptor = await fetchAPIData(PAGES.API);
    if (pageDescriptor?.data) {
      return setPageData({ ...pageDescriptor?.data });
    }
    return setPageData({ fields: {}, PAGES });
  }, []);

  return (
    <Main.Body>
      <WideBanner
        clientName={AuthUtil.getDisplayName(user)}
        banner={pageData?.fields?.banner}
        themeName={BANNER_THEMES.Api}
      />
      <Card cardContent={pageData?.fields?.content} />
    </Main.Body>
  );
};

ApiPage.propTypes = {
  fetchAPIData: PropTypes.func,
};

export default compose(
  connect(null, {
    fetchAPIData: fetchPageData,
  }),
  withTrack(trackProps(API_PAGE))
)(ApiPage);
