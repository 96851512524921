import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { userApis } from "../../../../../apis";
import { prepareUpdateData } from "./model";

export const ShippingDefaultsActionTypes = createActionTypes(
  "SHIPPING_DEFAULTS",
  {
    UPDATE_SHIPPING_DEFAULTS: createAsyncActionTypes(
      "UPDATE_SHIPPING_DEFAULTS"
    ),
  }
);

export const updateForm = createAsyncAction(
  body => userApis.updateShippingDefaults(prepareUpdateData(body)),
  ShippingDefaultsActionTypes.UPDATE_SHIPPING_DEFAULTS
);
